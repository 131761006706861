import React from 'react'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import styled from 'styled-components'

import WistiaVideo from '../common/WistiaVideo';

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #1ea0a0, #f0f5f5);
`

const GreenGradientSectionSlice = ({ slice }) => (
  <StyledDiv2 className="py-20">
    <div className="green-gradient-slice-section w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
      {slice.primary.green_gradient_section_title
        ?
        <h2 className="text-3xl md:text-4xl text-white font-bold text-center">{slice.primary.green_gradient_section_title}</h2>
        :
        null
      }

      {slice.primary.green_gradient_section_content
        ?
        <RichText
          render={slice.primary.green_gradient_section_content.richText}
          htmlSerializer={htmlSerializer}
        />
        :
        null
      }

      <div className='shadow-lg mt-12'>
        <WistiaVideo wistiaID={slice.primary.wistia_video_id} />
      </div>
    </div>
  </StyledDiv2>
)

export default GreenGradientSectionSlice