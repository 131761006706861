import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

const CaseStudySectionSlice = ({ slice }) => {
  const sectionImg = getImage(slice.primary.case_study_image)

  return (
    <div className="bg-white py-20">
      {slice.primary.case_study_section_title
        ?
        <div className="w-11/12 md:w-8/12 xl:w-5/12 mx-auto border-b-2 border-site-gray-dark pb-5">
          <h2 className="text-site-red text-2xl md:text-4xl uppercase font-extrabold text-center tracking-wider">{slice.primary.case_study_section_title}</h2>
        </div>
        :
        null
      }

      <div className="w-11/12 lg:w-10/12 max-w-screen-lg grid grid-cols-1 md:grid-cols-2 mx-auto mt-16 px-0 lg:px-8">
        <div className="bg-site-red p-6 lg:p-12">
          <h2 className="text-white text-4xl font-bold">{slice.primary.case_study_title ? slice.primary.case_study_title : "Title"}</h2>
          <h3 className="text-white text-3xl font-bold mt-6 mb-8">{slice.primary.case_study_subtitle ? slice.primary.case_study_subtitle : "Subtitle"}</h3>
          <hr/>
          <h3 className="text-sm text-white mt-6 uppercase">Industry Specific</h3>
          <h4 className="text-white text-2xl font-bold mt-1">{slice.primary.case_study_industry ? slice.primary.case_study_industry : null}</h4>
          <h3 className="text-sm text-white mt-6 uppercase">Annual Gross Revenue</h3>
          <h4 className="text-white text-2xl font-bold mt-1">{slice.primary.case_study_revenue ? slice.primary.case_study_revenue : null}</h4>
          <h3 className="text-sm text-white mt-6 uppercase">Owner Structure</h3>
          <h4 className="text-white text-2xl font-bold mt-1">{slice.primary.case_study_owner_structure ? slice.primary.case_study_owner_structure : null}</h4>
          <h3 className="text-sm text-white mt-6 uppercase">Employees</h3>
          <h4 className="text-white text-2xl font-bold mt-1">{slice.primary.case_study_employees ? slice.primary.case_study_employees : null}</h4>
        </div>
        <div className="bg-gray-200 grid">
          <GatsbyImage
            style={{
              gridArea: "1/1",
              objectFit: "cover",
            }}
            image={sectionImg}
          />
        </div>
      </div>

      {slice.items
        ?
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg mx-auto px-0 lg:px-8">
            {slice.items.map(item => (
              item.subsection_background_color === "Gray"
              ?
                <div className={`case-studies ${item.two_column_bullet_list ? "list-two-col" : "list-one-col"} case-study-gray bg-gray-100 px-6 lg:px-12 py-16`}>
                  <h2 className="text-2xl font-bold uppercase mb-6">{item.case_study_subsection_title}</h2>
                  <RichText
                    render={item.case_study_subsection_text.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              :
              item.subsection_background_color === "White"
              ?
                <div className={`case-studies ${item.two_column_bullet_list ? "list-two-col" : "list-one-col"} case-study-white bg-white px-6 lg:px-12 py-16`}>
                  <h2 className="text-2xl font-bold uppercase mb-6">{item.case_study_subsection_title}</h2>
                  <RichText
                    render={item.case_study_subsection_text.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              :
              item.subsection_background_color === "Red"
              ?
                <div className={`case-studies ${item.two_column_bullet_list ? "list-two-col" : "list-one-col"} case-study-red bg-site-red px-6 lg:px-12 py-16`}>
                  <h2 className="text-2xl font-bold uppercase mb-6">{item.case_study_subsection_title}</h2>
                  <RichText
                    render={item.case_study_subsection_text.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              :
              item.subsection_background_color === "Green"
              ?
                <div className={`case-studies ${item.two_column_bullet_list ? "list-two-col" : "list-one-col"} case-study-green bg-green-100 px-6 lg:px-12 py-16 border border-site-gray-dark`}>
                  <h2 className="text-2xl font-bold uppercase mb-6">{item.case_study_subsection_title}</h2>
                  <RichText
                    render={item.case_study_subsection_text.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              :
              null
            ))}
          </div>
        :
        null
      }

      {slice.primary.case_study_button
        ?
        <div className="w-11/12 md:w-8/12 xl:w-5/12 max-w-screen-lg mx-auto px-0 lg:px-8 mt-8 lg:mt-16">
          {slice.primary.case_study_button_link.link_type === "Document" || slice.primary.case_study_button_link.link_type === "Any"
            ?
              <Link to={slice.primary.case_study_button_link.uid ? `/${slice.primary.case_study_button_link.uid}` : "/"}>
                <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-2xl text-white uppercase tracking-wider px-4 py-3">{slice.primary.case_study_button ? slice.primary.case_study_button : "Button Name"}</button>
              </Link>
            :
            slice.primary.case_study_button_link.link_type === "Web" || slice.primary.case_study_button_link.link_type === "Media"
            ?
              <a href={slice.primary.case_study_button_link.url} target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-red hover:bg-site-red-hover text-base text-white uppercase tracking-wider px-6 py-3">{slice.primary.case_study_button ? slice.primary.case_study_button : "Button Name"}</button>
              </a>
            :
            null
          }
        </div>
        :
        null
      }
    </div>
  )
}

export default CaseStudySectionSlice