import React from 'react'

import PlainSectionSlice from './plain-section-slice'
import GreenBubbleSectionSlice from './green-bubble-section-slice'
import AccordionSectionSlice from './accordion-section-slice'
import GreenGradientSectionSlice from './green-gradient-section-slice'
import TwoColumnSectionSlice from './two-column-section-slice'
import CaseStudySectionSlice from './case-study-section-slice'
import FormSlice from './form-slice'

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    plain_section: PlainSectionSlice,
    bubble_header_section: GreenBubbleSectionSlice,
    accordion_section: AccordionSectionSlice,
    green_gradient_section: GreenGradientSectionSlice,
    two_column_section: TwoColumnSectionSlice,
    case_study_section: CaseStudySectionSlice,
    // case_study_downloads: CaseStudyDownloadsSlice,
    form: FormSlice,
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
}

export default SliceZone