import React from "react"
import HubspotForm from 'react-hubspot-form'

const FormSlice = ({ slice }) => {
  return slice.items
    ? slice.items.map(item => (
        <div className="max-w-lg mx-auto py-10 px-4 lg:px-0 lg:pb-20">
          <div className="relative bg-white flex flex-col items-center border-2 border-site-gray-dark rounded-3xl py-8 shadow-lg">
            <div className="absolute top-0 bg-site-green py-4 px-8 rounded-full -mt-8 mx-auto">
              <h2 className="text-white text-base md:text-xl lg:text-2xl uppercase font-bold text-center">
                {item.form_title}
              </h2>
            </div>

            {item.form_id
            ?
            <div className="w-10/12 md:w-8/12 mt-12">
              <HubspotForm
                portalId='23182726'
                formId={item.form_id}
                loading={<div className='italic'>Loading contact form...</div>}
              />
            </div>
            :
            <div className="w-10/12 md:w-8/12 mt-12">
              <HubspotForm
                portalId='23182726'
                formId='dfa38579-9c57-4e76-8e3d-5fd33b0f232e'
                loading={<div className='italic'>Loading contact form...</div>}
              />
            </div>
            }
          </div>
        </div>
      ))
    : null
}

export default FormSlice
