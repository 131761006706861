import React from 'react'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

const PlainSectionSlice = ({ slice }) => (
  <div className="py-20">
    <div className="plain-slice-section w-11/12 lg:w-10/12 max-w-screen-md mx-auto">
      <RichText
        render={slice.primary.section_content.richText}
        htmlSerializer={htmlSerializer}
      />
    </div>
  </div>
)

export default PlainSectionSlice