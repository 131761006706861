import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

const StyledDiv = styled.div`
  .accordion {
    border: none;
  }
  .accordion__button {
    background-color: transparent;
    color: #bb0000;
    font-weight: 900;
    font-size: 24px;
  }
  .accordion__item {
    border: none;
    font-size: 20px;
  }
`

const AccordionSectionSlice = ({ slice }) => (
  <div className="bg-site-green-light pt-16 md:pt-32 pb-12 md:pb-24">
    <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-gray-dark mx-auto p-8 rounded-3xl shadow-lg overflow-hidden">
      <StyledDiv className="w-full max-w-2xl flex flex-col justify-center z-10">
        <Accordion>
          {slice.items.map((item) => {
            return (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {item.accordion_item_title}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {item.accordion_item_text}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </StyledDiv>
      <div className="absolute bottom-0 -mb-2 opacity-20 z-0">
        <StaticImage
          src="../../images/backgrounds/sra-mtn.png"
          width={600}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="background image"
        />
      </div>
    </div>
  </div>
)

export default AccordionSectionSlice