import React from "react"
import { graphql } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import LayoutUpdate from "../components/layout-update"
import HeroSectionUpdate from "../components/common/hero-section-update"
import Seo from "../components/seo"
import SliceZone from "../components/slices/slice-zone"
import SectionMobileCTAnew from "../components/sections/section-mobile-cta-new"

const PageTemplate = ({ data }) => {
  const doc = data.prismicPage
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgHero = getImage(doc.data.page_hero_background)

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : null}
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
        noIndex={doc.data.page_no_index ? true : false}
      />

      <div className={doc.uid === "831b-plan" ? "hero-section" : ""}>
        <HeroSectionUpdate
          imgSrc={imgHero ? imgHero : null}
          title={doc.data.page_title}
          withButton={doc.data.cta_button}
          twoButtons={doc.data.how_many_buttons}
          buttonOneTypeHover={doc.data.cta_button_one_type}
          buttonOneLinkType={doc.data.cta_button_link.link_type}
          buttonOneLinkUid={doc.data.cta_button_link.uid}
          buttonOneLinkUrl={doc.data.cta_button_link.url}
          buttonOneName={doc.data.cta_button_text}
          buttonOneNameHover={doc.data.cta_button_one_hover_text}
          buttonTwoTypeHover={doc.data.cta_button_two_type}
          buttonTwoLinkType={doc.data.cta_button_two_link.link_type}
          buttonTwoLinkUid={doc.data.cta_button_two_link.uid}
          buttonTwoLinkUrl={doc.data.cta_button_two_link.url}
          buttonTwoName={doc.data.cta_button_two_text}
          buttonTwoNameHover={doc.data.cta_button_two_hover_text}
          redBubble={doc.data.red_bubble}
          redBubbleText={doc.data.red_bubble_text}
        />
      </div>

      <SliceZone slices={doc.data.body} />

      {doc.uid === "831b-plan"
        ?
        <SectionMobileCTAnew />
        :
        null
      }
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PageTemplate)

export const pageTemplateNewQuery = graphql`
  query($id: String!) {
    prismicPage(id: { eq: $id }) {
      _previewable
      id
      uid
      data {
        page_title
        page_short_description
        page_no_index
        red_bubble
        red_bubble_text
        page_hero_background {
          gatsbyImageData(
            width: 1920
          )
        }
        cta_button
        how_many_buttons
        cta_button_one_type
        cta_button_text
        cta_button_one_hover_text
        cta_button_link {
          link_type
          uid
          url
        }
        cta_button_two_type
        cta_button_two_text
        cta_button_two_hover_text
        cta_button_two_link {
          link_type
          uid
          url
        }
        body {
          __typename
          ... on PrismicPageDataBodyPlainSection {
            id
            slice_type
            primary {
              section_content {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyBubbleHeaderSection {
            id
            slice_type
            primary {
              green_bubble_title
              green_bubble_full_width_background
              green_bubble_content {
                richText
              }
              two_column_bullet_list
              green_bubble_buttons
              green_bubble_button_name_1
              green_bubble_button_name_2
              green_bubble_button_link_1 {
                uid
                link_type
                url
              }
              green_bubble_button_link_2 {
                uid
                link_type
                url
              }
            }
            items {
              feature_image {
                gatsbyImageData(width: 512)
              }
              feature_details
              feature_title
            }
          }
          ... on PrismicPageDataBodyAccordionSection {
            id
            slice_type
            items {
              accordion_item_text
              accordion_item_title
            }
          }
          ... on PrismicPageDataBodyGreenGradientSection {
            id
            slice_type
            primary {
              green_gradient_section_title
              green_gradient_section_content {
                richText
              }              
              wistia_video_id
            }
          }
          ... on PrismicPageDataBodyTwoColumnSection {
            id
            slice_type
            primary {
              two_col_section_title
              two_col_section_text
              wistia_video_id
              two_col_section_image {
                alt
                gatsbyImageData(
                  width: 555
                )
              }
              two_col_section_buttons
              two_col_section_button_name_2
              two_col_section_button_name_1
              two_col_section_button_link_2 {
                link_type
                uid
                url
              }
              two_col_section_button_link_1 {
                link_type
                uid
                url
              }
              two_col_section_background
              two_col_section_image_position
            }
          }
          ... on PrismicPageDataBodyCaseStudySection {
            id
            slice_type
            primary {
              case_study_section_title
              case_study_title
              case_study_subtitle
              case_study_revenue
              case_study_owner_structure
              case_study_industry
              case_study_employees
              case_study_image {
                alt
                gatsbyImageData(
                  width: 1920
                )
              }
              case_study_button
              case_study_button_link {
                link_type
                uid
                url
              }
            }
            items {
              case_study_subsection_title
              subsection_background_color
              case_study_subsection_text {
                richText
              }
              two_column_bullet_list
            }
          }
          ... on PrismicPageDataBodyCaseStudyDownloads {
            id
            slice_type
            primary {
              section_title
              column_one_title
              column_two_title
            }
            items {
              case_study_title
              button_name
              file_link {
                link_type
                uid
                url
              }
              column_position
            }
          }
          ... on PrismicPageDataBodyForm {
            id
            slice_type
            items {
              form_title
              form_id
            }
          }
        }
      }
    }
  }
`