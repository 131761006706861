import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import WistiaVideo from '../common/WistiaVideo';

const pStyle = "text-base mb-6 text-center xl:text-left"

const TwoColumnSectionSlice = ({ slice }) => {
  const sectionImg = getImage(slice.primary.two_col_section_image)

  return (
    <div className={`${slice.primary.two_col_section_background ? "bg-gray-100" : "bg-white"} pt-16 md:pt-24 pb-12 md:pb-24`}>
      <div className="relative w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-2 gap-8 mx-auto">
        <div className={`${slice.primary.two_col_section_image_position ? "xl:order-last" : "order-first"} w-full flex justify-center`}>
          {slice.primary.wistia_video_id
            ?
            <div className="w-full">
              <WistiaVideo wistiaID={slice.primary.wistia_video_id} />
            </div>
            :
            <GatsbyImage
              image={sectionImg}
              alt={slice.primary.two_col_section_title}
              className="shadow-lg"
            />
          }
        </div>
        <div className="mb-8">
          {slice.primary.two_col_section_title
            ?
            <div className="lg:w-10/12 mx-auto border-b-2 border-site-gray-dark pb-5 mb-8">
              <h2 className="text-site-red text-xl md:text-3xl uppercase font-bold text-center tracking-wider">{slice.primary.two_col_section_title}</h2>
            </div>
            :
            null
          }

          {slice.primary.two_col_section_text
            ?
            <p className={pStyle}>{slice.primary.two_col_section_text}</p>
            :
            <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra eros ut sollicitudin eleifend. In malesuada nunc eu lectus sollicitudin, id efficitur mauris iaculis. Phasellus aliquet, est semper scelerisque convallis, elit tortor dignissim arcu, et viverra arcu ligula vel urna. Ut sollicitudin at mi efficitur feugiat. Nunc volutpat quis augue sollicitudin elementum. Suspendisse erat eros, consectetur a placerat et, ultricies consequat mi. Sed arcu lorem, imperdiet at auctor vel, pharetra et nibh.</p>
          }

          {slice.primary.two_col_section_buttons
            ?
            <div className="flex flex-col md:flex-row items-center justify-center xl:justify-start mt-8 md:space-x-6 space-y-6 md:space-y-0">
              {slice.primary.two_col_section_button_link_1.link_type === "Document" || slice.primary.two_col_section_button_link_1.link_type === "Any"
                ?
                  <Link to={slice.primary.two_col_section_button_link_1.uid ? `/${slice.primary.two_col_section_button_link_1.uid}` : "/"}>
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base text-white uppercase tracking-wider px-6 py-3">{slice.primary.two_col_section_button_name_1 ? slice.primary.two_col_section_button_name_1 : "Button Name"}</button>
                  </Link>
                :
                slice.primary.two_col_section_button_link_1.link_type === "Web" || slice.primary.two_col_section_button_link_1.link_type === "Media"
                ?
                  <a href={slice.primary.two_col_section_button_link_1.url} target="_blank" rel="noopener noreferrer">
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base text-white uppercase tracking-wider px-6 py-3">{slice.primary.two_col_section_button_name_1 ? slice.primary.two_col_section_button_name_1 : "Button Name"}</button>
                  </a>
                :
                null
              }

              {slice.primary.two_col_section_button_link_2.link_type === "Document" || slice.primary.two_col_section_button_link_2.link_type === "Any"
                ?
                  <Link to={slice.primary.two_col_section_button_link_2.uid ? `/${slice.primary.two_col_section_button_link_2.uid}` : "/"}>
                    <button className="w-full bg-white text-base text-site-red border border-site-red hover:bg-site-red hover:text-white uppercase tracking-wider px-6 py-3">{slice.primary.two_col_section_button_name_2 ? slice.primary.two_col_section_button_name_2 : "Button Name"}</button>
                  </Link>
                :
                slice.primary.two_col_section_button_link_2.link_type === "Web" || slice.primary.two_col_section_button_link_2.link_type === "Media"
                ?
                  <a href={slice.primary.two_col_section_button_link_2.url} target="_blank" rel="noopener noreferrer">
                    <button className="w-full bg-white text-base text-site-red border border-site-red hover:bg-site-red hover:text-white uppercase tracking-wider px-6 py-3">{slice.primary.two_col_section_button_name_2 ? slice.primary.two_col_section_button_name_2 : "Button Name"}</button>
                  </a>
                :
                null
              }
            </div>
            :
            null
          }
        </div>
      </div>
    </div>
  )
}

export default TwoColumnSectionSlice