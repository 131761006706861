import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const SectionMobileCTAnew = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.hero-section')
      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom
        if (heroBottom < 0) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <div  className="block md:hidden w-full bg-site-gray-dark">
      <div
        className="bg-site-black fixed bottom-0 w-full flex items-center justify-center z-50 border-t border-gray-500 pt-3 pb-6"
      >
        <Link to="/qualify">
          <button className="mobile-cta w-full md:w-auto bg-site-green-button font-display font-semibold text-sm md:text-lg text-white uppercase tracking-wider px-3 py-2">
            Begin Assessment
          </button>
        </Link>
      </div>
    </div>
  )
}

export default SectionMobileCTAnew